.info-share {
  text-align: right;
  .nativeShare-box {
    display: inline-block; } }
.form-message {
  .form-group {
    width: 100%; }
  textarea {
    height: 166px !important; } }
.nav-header-v3 {
  width: 100%; }
